import React from 'react'

import { UilArrowDown } from '@iconscout/react-unicons'

import { DividerComponent } from '~/components'

import CardCarousel from '../Carrousell/CardCarousel'
import { CustomHtmlProps, Props } from './StepAccordionProject.interfaces'
import * as S from './StepAccordionProject.styles'

const CustomHtml: React.FC<CustomHtmlProps> = ({ htmlContent }) => {
  const modifiedHtml = htmlContent
    ?.replace(/color: rgb\(\d+, \d+, \d+\)/g, 'color: black')
    .replace(/<ul([^>]*)>/g, '<ul$1 style="list-style-type: disc; margin-left: 5px;">')
    .replace(/<ol([^>]*)>/g, '<ul$1 style="list-style-type: disc; margin-left: 5px;">')
    .replace(/<li([^>]*)>/g, '<li$1 style="margin-left: 5px;">')
  return <S.AccordionText dangerouslySetInnerHTML={{ __html: modifiedHtml }} />
}

export const StepAccordionProject: React.FC<Props> = ({
  txGuidance,
  type,
  key,
  title,
  accordionRef,
  expaded = true,
  onToggle,
  nonCollapsible = false,
}) => (
  <S.AccordionContainer
    expanded={nonCollapsible || expaded}
    onChange={nonCollapsible ? undefined : onToggle}
    ref={accordionRef}
  >
    {!nonCollapsible && (
      <S.AccordionHeader
        expandIcon={
          <S.CircleIcon>
            <UilArrowDown />
          </S.CircleIcon>
        }
      >
        <S.AccordionTitle type={type}>{title}</S.AccordionTitle>
      </S.AccordionHeader>
    )}

    {nonCollapsible && (
      <S.StepOrientationContainerTitle>
        <S.AccordionTitlenoNCollapsible type={type}>{title}</S.AccordionTitlenoNCollapsible>
      </S.StepOrientationContainerTitle>
    )}

    <S.StepOrientationContainer>
      {/* Mapeando as orientações */}
      {txGuidance &&
        txGuidance?.map((guidance, index) => (
          <React.Fragment key={guidance.idProjectStageOrientation}>
            <S.StepOrientationContainerTitle>
              {/* Renderizando o conteúdo de orientação */}
              <CustomHtml htmlContent={String(guidance.txOrientationCode)} />
            </S.StepOrientationContainerTitle>

            {/* Renderizando as referências de apoio, se existirem */}
            {guidance.projectStageSupportReference && guidance.projectStageSupportReference.length > 0 && (
              <S.ReferenceContainer>
                <DividerComponent className="padded" />
                <S.AccordionTitle type={type} className="reference">
                  Referências de Apoio
                </S.AccordionTitle>
                <CardCarousel
                  key={`carousel-${index}`}
                  items={guidance.projectStageSupportReference}
                  type={'firstStep'}
                />
              </S.ReferenceContainer>
            )}

            {/* Espaçamento entre as orientações */}
            {index < txGuidance.length - 1 && <DividerComponent className="padded" />}
          </React.Fragment>
        ))}
    </S.StepOrientationContainer>
  </S.AccordionContainer>
)
